export default function () {
	'use script';

	/**
	 * Redirect visitors to configured browsers language.
	 * Forward to EN in case if no translation was found.
	 */
	const coockieName = 'dev_redirect';

	if (!Cookies.get(coockieName)) {
		Cookies.set(coockieName, '1', { SameSite: 'Strict' });

		const htmlElement = document.getElementsByTagName('html')[0];
		const htmlLang = htmlElement.getAttribute('lang');
		let language = navigator.language || navigator.userLanguage;

		if (typeof htmlLang === 'string' && typeof language === 'string') {
			language = language.split('-')[0];

			if ((new RegExp('^' + htmlLang, 'i')).test(language) === false) {
				let translation = document.querySelector('link[hreflang^="' + language + '-"]');

				if (!translation) {
					translation = document.querySelector('link[hreflang^="en-US"]');
				}

				if (translation && translation.href.length > 0) {
					window.location.href = translation.href;
				}
			}
		}
	}
}
